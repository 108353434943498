import { defineStore } from "pinia"

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      token: "" as string,
      refreshToken: "" as string,
      auth: false as boolean,
      retry: false as boolean
    }
  },

  actions: {
    async signUp({ email, password, country, username, captchaResponse }: { email: string, password: string, country: string, username: string, captchaResponse: string }) {
      return await useNewFetch('/auth/native/sign-up', {
        method: 'POST',
        body: {
          email,
          password,
          country,
          username,
        },
        headers: {
          'captcha': captchaResponse
        }
      });
    },

    async signUpConfirm({ email, token }: { email: string, token: string }) {
      return await useNewFetch('/auth/native/sign-up/confirm', {
        method: 'POST',
        body: {
          email,
          token,
        },
      });
    },

    async signIn({ login, password }: { login: string, password: string }) {
      return await useNewFetch('/auth/native/sign-in', {
        method: 'POST',
        body: {
          login,
          password,
        },
      });
    },

    async googleAuth({ code, scope }: { code: string, scope: string }) {
      return await useNewFetch(`/auth/google`, {
        method: 'GET',
        params: {
          code,
          scope,
        },
      });
    },

    login({ token, refreshToken }: { token: string, refreshToken: string }) {
      this.token = token;
      this.refreshToken = refreshToken;
      this.auth = true

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
    },

    async logOut() {
       await useNewFetch('/auth/sign-out', {method: 'POST'});
       this.auth = false
       this.token = ''
       this.refreshToken = ''
       await navigateTo({ path: '/login' })
       if (process.client) {
       localStorage.removeItem('token');
       localStorage.removeItem('refreshToken');
       }
    },

    async refreshJwtToken({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }) {
      this.retry = true
      return await useNewFetch('/auth/token/refresh', {
        method: 'POST',
        body: {
          accessToken,
          refreshToken,
        },
      });
    },

    async passwordRecovery({ email }: { email: string }) {
      return await useNewFetch('/auth/native/password-recovery', {
        method: 'POST',
        body: {
         email
        },
      });
    },

    async passwordRecoveryConfirm({ email, token, password }: { email: string; token: string, password: string }) {
      return await useNewFetch('/auth/native/password-recovery/confirm', {
        method: 'POST',
        body: {
         email,
         token,
         password
        },
      });
    },


    async testAuth() {
      await useNewFetch('/auth/native/password-change', {method: 'POST',
      body: {
        login:"test",
        password: "test",
      },
    });
   },
  }
})