import { default as InvoicesnWUlYVOMtrMeta } from "/vercel/path0/pages/admin/Invoices.vue?macro=true";
import { default as serverspIl9z23TaUMeta } from "/vercel/path0/pages/admin/servers.vue?macro=true";
import { default as usersjvZRxTajERMeta } from "/vercel/path0/pages/admin/users.vue?macro=true";
import { default as _91id_93IY3Di9BucjMeta } from "/vercel/path0/pages/cabinet/billing/documents/[id].vue?macro=true";
import { default as indexYGLd2N1FjTMeta } from "/vercel/path0/pages/cabinet/billing/documents/index.vue?macro=true";
import { default as layout6i7ftTmPcnMeta } from "/vercel/path0/pages/cabinet/billing/documents/layout.vue?macro=true";
import { default as indexKAxxuT5wsFMeta } from "/vercel/path0/pages/cabinet/billing/index.vue?macro=true";
import { default as _91id_93HD2tbgpZsdMeta } from "/vercel/path0/pages/cabinet/overview/[id].vue?macro=true";
import { default as indexhLUy4fpwD6Meta } from "/vercel/path0/pages/cabinet/overview/index.vue?macro=true";
import { default as indexKFT9GsRNjeMeta } from "/vercel/path0/pages/cabinet/support/index.vue?macro=true";
import { default as confirm4ylEkIrBkwMeta } from "/vercel/path0/pages/forgot-password/confirm.vue?macro=true";
import { default as index09rJVUvML3Meta } from "/vercel/path0/pages/forgot-password/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as googleoNSgIzYjIFMeta } from "/vercel/path0/pages/oauth/google.vue?macro=true";
import { default as confirmSrJCgBMzNOMeta } from "/vercel/path0/pages/registration/confirm.vue?macro=true";
import { default as indexheb97oeggIMeta } from "/vercel/path0/pages/registration/index.vue?macro=true";
import { default as tariffslqwsIZ0b5QMeta } from "/vercel/path0/pages/tariffs.vue?macro=true";
export default [
  {
    name: "admin-Invoices",
    path: "/admin/Invoices",
    meta: InvoicesnWUlYVOMtrMeta || {},
    component: () => import("/vercel/path0/pages/admin/Invoices.vue")
  },
  {
    name: "admin-servers",
    path: "/admin/servers",
    meta: serverspIl9z23TaUMeta || {},
    component: () => import("/vercel/path0/pages/admin/servers.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersjvZRxTajERMeta || {},
    component: () => import("/vercel/path0/pages/admin/users.vue")
  },
  {
    name: "cabinet-billing-documents-id",
    path: "/cabinet/billing/documents/:id()",
    meta: _91id_93IY3Di9BucjMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/billing/documents/[id].vue")
  },
  {
    name: "cabinet-billing-documents",
    path: "/cabinet/billing/documents",
    meta: indexYGLd2N1FjTMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/billing/documents/index.vue")
  },
  {
    name: "cabinet-billing-documents-layout",
    path: "/cabinet/billing/documents/layout",
    meta: layout6i7ftTmPcnMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/billing/documents/layout.vue")
  },
  {
    name: "cabinet-billing",
    path: "/cabinet/billing",
    meta: indexKAxxuT5wsFMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/billing/index.vue")
  },
  {
    name: "cabinet-overview-id",
    path: "/cabinet/overview/:id()",
    meta: _91id_93HD2tbgpZsdMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/overview/[id].vue")
  },
  {
    name: "cabinet-overview",
    path: "/cabinet/overview",
    meta: indexhLUy4fpwD6Meta || {},
    component: () => import("/vercel/path0/pages/cabinet/overview/index.vue")
  },
  {
    name: "cabinet-support",
    path: "/cabinet/support",
    meta: indexKFT9GsRNjeMeta || {},
    component: () => import("/vercel/path0/pages/cabinet/support/index.vue")
  },
  {
    name: "forgot-password-confirm",
    path: "/forgot-password/confirm",
    meta: confirm4ylEkIrBkwMeta || {},
    component: () => import("/vercel/path0/pages/forgot-password/confirm.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: index09rJVUvML3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "oauth-google",
    path: "/oauth/google",
    meta: googleoNSgIzYjIFMeta || {},
    component: () => import("/vercel/path0/pages/oauth/google.vue")
  },
  {
    name: "registration-confirm",
    path: "/registration/confirm",
    meta: confirmSrJCgBMzNOMeta || {},
    component: () => import("/vercel/path0/pages/registration/confirm.vue")
  },
  {
    name: "registration",
    path: "/registration",
    meta: indexheb97oeggIMeta || {},
    component: () => import("/vercel/path0/pages/registration/index.vue")
  },
  {
    name: "tariffs",
    path: "/tariffs",
    meta: tariffslqwsIZ0b5QMeta || {},
    component: () => import("/vercel/path0/pages/tariffs.vue")
  }
]