export const useNewFetch: typeof useFetch = (request, opts?) => {
  const config = useRuntimeConfig()
  const auth = useAuthStore()
  const defaultHeaders = {
    'Authorization': `Bearer ${auth.token}`
  }

  return useFetch(request, {
    baseURL: config.public.baseURL, headers: defaultHeaders, ...opts, async onResponseError({ request, response, options }) {
      if (response.status === 401) {
          if(auth.retry){
            return
          }
          const { error, data } = await auth.refreshJwtToken({ accessToken: auth.token, refreshToken: auth.refreshToken })
          if (data.value) {
           return
          }
          const newHeaders = {
            ...options.headers,
            'Authorization': `Bearer 'zzz'`
          }

          return useFetch(request, {
            ...opts,
            headers: newHeaders,
          })
       
      }
    },
  })
}